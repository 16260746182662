import React from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WorkListing from '../components/WorkListing'

const Intro = styled.div`
  ${tw`text-2xl md:text-3xl max-w-4xl mx-auto py-8 md:py-4 px-16 mb-20`}
`

const WorkPage = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHome {
        data {
          intro {
            text
          }
          og_image {
            url
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Digital / UX Designer"
        image={data.prismicHome.data.og_image.url}
        description="Hello 👋 I’m Emma, a Digital / UX Designer with 10 years work experience from the digital industry."
      />
      <Intro>
        <Link to="/about/">{data.prismicHome.data.intro[0].text}</Link>
      </Intro>
      <WorkListing />
    </Layout>
  )
}

export default WorkPage
